import React, {
    forwardRef,
    useRef,
    useImperativeHandle,
    useCallback,
    useEffect,
    useState,
} from 'react';

import cls from '@soluto-design/styles/cls';

import useDisableScroll from '../../../hooks/useDisableScroll';

export interface ModalProps
    extends React.PropsWithoutRef<React.HTMLAttributes<HTMLDialogElement>> {
    id: string;
    fullscreen?: boolean;
    className?: string;
    children: React.ReactNode;
    onMount?: () => void;
    open?: boolean;
    disableClose?: boolean;
    classes?: {
        root?: string;
        wrapper?: string;
    };
    onClose?: () => void;
}

export interface ModalRef {
    open: () => void;
    close: (historyBack?: boolean) => void;
    toggle: () => void;
}

const Modal = forwardRef<ModalRef, ModalProps>(function Modal(
    {
        id,
        fullscreen = false,
        className,
        children,
        open,
        title,
        disableClose = false,
        classes = {
            root: '',
            wrapper: '',
        },
        onClose,
    },
    ref,
) {
    const [prevOpen, setPrevOpen] = useState<boolean>(false);

    const modalRef = useRef<HTMLDialogElement>(null);

    const [blockScroll, allowScroll] = useDisableScroll();

    const thisOpen = useCallback(() => {
        modalRef?.current?.showModal();
        blockScroll();
        setPrevOpen(true);
    }, [blockScroll]);

    const thisClose = useCallback(
        (back = true) => {
            modalRef?.current?.close();
            allowScroll();

            if (back) {
                window.history.back();
            }

            if (typeof onClose === 'function') {
                onClose();
            }

            setPrevOpen(false);
        },
        [allowScroll, onClose],
    );

    useImperativeHandle(
        ref,
        () => ({
            open: thisOpen,
            close: thisClose,
            toggle: () => {
                if (modalRef?.current?.open) {
                    thisClose();
                    return;
                }

                thisOpen();
            },
        }),
        [thisOpen, thisClose],
    );

    useEffect(() => {
        if (typeof window === 'undefined' || !prevOpen) return;

        function onPressEscape(e: KeyboardEvent) {
            if (e.key !== 'Escape') return;
            if (disableClose) e.preventDefault();
            thisClose();
        }

        document.addEventListener('keydown', onPressEscape);

        return () => {
            if (typeof document === 'undefined' || !prevOpen) return;
            document.removeEventListener('keydown', onPressEscape);
        };
    }, [prevOpen, disableClose, thisClose]);

    useEffect(() => {
        if (typeof window === 'undefined' || !open) return;

        const handleBackButton = (event) => {
            event.preventDefault();
            if (disableClose) return;
            thisClose(false);
        };

        window.history.pushState(null, '', window.location.href);
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [open, thisClose, disableClose]);

    const handleOutsideClick = useCallback(
        (event: React.MouseEvent<HTMLDialogElement>) => {
            const dialog = event.currentTarget;
            const target = event.target as HTMLElement;

            if (!target.contains(dialog)) return;

            thisClose();
        },
        [thisClose],
    );

    useEffect(() => {
        if (open) {
            thisOpen();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, thisOpen]);

    return (
        <dialog
            id={id}
            ref={modalRef}
            open={prevOpen}
            aria-label={title}
            aria-modal="true"
            {...(disableClose
                ? {}
                : {
                      onClick: handleOutsideClick,
                  })}
            className={cls(
                'fixed overflow-hidden z-50 m-0',
                {
                    'inset-0 w-screen h-screen max-h-screen !max-w-[100vw]':
                        fullscreen,
                    '-translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 transform w-screen max-w-[450px] max-h-[90vh] rounded-large h-full':
                        !fullscreen,
                },

                className,
                classes?.root,
            )}>
            <div
                className={cls(
                    'px-margin py-lg bg-background-primary theme-dark w-full h-full flex flex-col overflow-auto',
                    classes?.wrapper,
                )}>
                {children}
            </div>
        </dialog>
    );
});

export default Modal;
